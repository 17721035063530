import PageTitle from '../Shell/PageTitle/PageTitle';
import * as S from './Membership.styles';

export type MembershipInfo = {};

/* Checkout */
export default function Membership() {
  return (
    <S.Membership id="membership-page">
      <PageTitle>Membership</PageTitle>
    </S.Membership>
  );
}
/* */
