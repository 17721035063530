import { useEffect, useState } from 'react';
import { DeepPartial, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  Form,
  FormActions,
  Link,
  Typography,
} from '@la/ds-ui-components';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import Page from 'domains/Shell/Page/Page';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { FamilyYouthAthleteAccess } from '../YouthAthleteAccess.types';
import YouthAthleteSectionList from '../YouthAthleteSectionList/YouthAthleteSectionList';
import * as S from '../EditMobileAppAccess.styles';

export type ReadonlyPartialFamilyYouthAthleteAccess =
  | Readonly<DeepPartial<FamilyYouthAthleteAccess>>
  | undefined;

export type EditMobileAppAccessOnSubmitProps = {
  originalData: ReadonlyPartialFamilyYouthAthleteAccess;
  newData: FamilyYouthAthleteAccess;
};

export interface EditMobileAppAccessPageProps {
  familyYouthAthleteAccess: FamilyYouthAthleteAccess;
  siteName: string;
  updateYouthAthleteFormValues?: ({
    originalData,
    newData,
  }: EditMobileAppAccessOnSubmitProps) => void;
  error?: string;
}

const youthAthleteSchema = yup.object({
  hasYouthAthletePermission: yup.bool(),
  email: yup.string().matches(/^($|.+@.+$)/, 'Please enter a valid email'), // Checks that is either empty or has an @ symbol.
});

const familySchema = yup.object({
  acceptTerms: yup.boolean().required(),
  youthAthletes: yup.array().of(youthAthleteSchema),
});

export default function EditMobileAppAccessPage({
  familyYouthAthleteAccess,
  siteName,
  updateYouthAthleteFormValues,
  error,
}: EditMobileAppAccessPageProps) {
  const {
    formState: { errors, isSubmitting, defaultValues },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FamilyYouthAthleteAccess>({
    defaultValues: familyYouthAthleteAccess,
    resolver: yupResolver(familySchema),
    shouldUnregister: false,
  });

  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [error]);

  const { youthAthletes } = familyYouthAthleteAccess;

  return (
    <Page>
      <PageTitle>Edit Child Mobile App Access</PageTitle>
      {showError ? (
        <ErrorCard message={error} closeCard={() => setShowError(false)} />
      ) : (
        ''
      )}
      <S.MobileAppAccessContainer>
        <S.MobileAppAccessHeadingContainer>
          <Typography variant="ui" size="medium" weight="bold">
            Grant your child access to join the LeagueApps PLAY app. {siteName}{' '}
            uses the PLAY app to share schedules and directions, track RSVPs,
            send announcements and chat messages, and more.
          </Typography>
        </S.MobileAppAccessHeadingContainer>
        <Form
          onSubmit={handleSubmit((data) => {
            if (!isSubmitting && updateYouthAthleteFormValues) {
              updateYouthAthleteFormValues({
                originalData: defaultValues,
                newData: data,
              });
            }
          })}
        >
          <YouthAthleteSectionList
            youthAthletes={youthAthletes}
            watch={watch}
            errors={errors}
            setValue={setValue}
          />
          <S.MobileAppAccessSection>
            <Typography variant="headline" size="medium">
              Accept terms and conditions
            </Typography>
            <Typography variant="ui" weight="bold" size="large">
              Accept for any children who have been given mobile app access
              permission.
            </Typography>
            <S.MobileAppAccessCheckboxContainer>
              <Checkbox
                ariaLabel="Yes, I accept the terms and conditions for my children who have been given mobile app access permission"
                id="accept-terms"
                name="accept-terms"
                size="large"
                required
                value={watch('acceptTerms') as any}
                onCheckedChange={(isChecked) => {
                  setValue('acceptTerms', isChecked);
                }}
              />
              <label htmlFor="accept-terms">
                Yes, I accept the{' '}
                <Link
                  isBold
                  size="large"
                  href="https://leagueapps.com/youth_athlete_terms"
                >
                  terms and conditions
                </Link>{' '}
                and{' '}
                <Link
                  isBold
                  size="large"
                  href="https://leagueapps.com/youth_athlete_privacy"
                >
                  privacy policy
                </Link>{' '}
                for my children who have been given mobile app access
              </label>
            </S.MobileAppAccessCheckboxContainer>
          </S.MobileAppAccessSection>
          <S.MobileAppAccessNoticeContainer>
            <Typography variant="ui" size="large" weight="bold">
              Upon saving these settings, a signup link will be sent to your
              enabled child's provided email address.
            </Typography>
          </S.MobileAppAccessNoticeContainer>

          <S.MobileAppAccessSubmitBtnsContainer>
            <Link href="/groupAccountDashboard" isBold size="large">
              Close
            </Link>
            <S.MobileAppAccessPrimaryBtnContainer>
              <FormActions>
                <Button type="submit" size="large" width="full">
                  Save Changes
                </Button>
              </FormActions>
            </S.MobileAppAccessPrimaryBtnContainer>
          </S.MobileAppAccessSubmitBtnsContainer>
        </Form>
      </S.MobileAppAccessContainer>
    </Page>
  );
}
