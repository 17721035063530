import styled from 'styled-components/macro';
import { ColumnGrid } from 'components/ColumnGrid/ColumnGrid.styles';
import { media } from 'lib/media-queries/mixins';
import { PageContent } from '../Shell/Page/Page.styles';

export const Membership = styled(PageContent)`
  ${ColumnGrid} {
    padding-bottom: 51px;

    ${media.TabletPortraitUp`
      padding-bottom: 39px;
    `}

    ${media.TabletLandscapeUp`
      padding-bottom: 0;
    `}
  }
`;
